import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'reactstrap';
import { SectionTitle } from '../../../components/shared/section-title';
import Blog from '../../../components/blog/layout-two';
import Pagination from '../../../components/pagination';
import styles from './RecentPostArea.module.scss';

export const RecentPostArea: React.FC = () => {
  const blogData = useStaticQuery(graphql`
    query RecentPostQuery {
      allMarkdownRemark(
        sort: { order: DESC, fields: frontmatter___date }
        limit: 10
        filter: { fields: { category: { ne: "Site" } } }
      ) {
        totalCount
        edges {
          node {
            fields {
              slug
              dateSlug
              postID
            }
            frontmatter {
              category
              date(formatString: "LL")
              format
              tags
              title
              image {
                childImageSharp {
                  fluid(
                    srcSetBreakpoints: [290, 480, 510, 720, 960, 1400, 1920]
                    maxWidth: 510
                    maxHeight: 350
                    quality: 100
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
            excerpt(pruneLength: 165, format: PLAIN, truncate: true)
          }
        }
      }
    }
  `);
  const blogs = blogData.allMarkdownRemark.edges;
  const { totalCount } = blogData.allMarkdownRemark;
  const postsPerPage = 10;
  const numberOfPages = Math.ceil(totalCount / postsPerPage);
  return (
    <section className={styles.recentPostArea}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle mb="24px" mt="16px" title="Recent Articles" />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            {blogs.map((blog: any) => (
              <Blog
                mb="50px"
                key={blog.node.fields.slug}
                content={{
                  ...blog.node.fields,
                  ...blog.node.frontmatter,
                  excerpt: blog.node.excerpt,
                }}
              />
            ))}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Pagination
              rootPage="/"
              currentPage={1}
              numberOfPages={numberOfPages}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
