import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Image from '../../../components/image';
import Heading from '../../../components/shared/heading';
import Text from '../../../components/shared/text';
import Social from '../../../components/socials/layout-two';
import styles from './AuthorArea.module.scss';

const AuthorArea = () => {
  const authorData = useStaticQuery(graphql`
    query HomeAuthorQuery {
      allAuthorsJson {
        edges {
          node {
            name
            tagline
            bio
            social {
              medium
              instagram
              linkedin
              twitter
              github
              soundcloud
            }
            image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationHeight
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  `);
  const {
    image,
    name,
    tagline,
    bio,
    social,
  } = authorData.allAuthorsJson.edges[0].node;
  return (
    <section className={styles.AuthorSection}>
      <Container>
        <Row className="align-items-center">
          <Col sm={4}>
            {image && (
              <figure className={styles.AuthorImg}>
                <Image fluid={image.childImageSharp.fluid} alt={name} />
              </figure>
            )}
          </Col>
          <Col sm={8}>
            <div className={styles.AuthorInfo}>
              {name && (
                <Heading
                  as={'h1'}
                  fontSize={['28px', '32px', '36px']}
                  fontWeight={700}
                  color={'#fff'}
                  lineHeight={1.1}
                >
                  {name}
                </Heading>
              )}
              {tagline && (
                <Heading
                  as="h2"
                  color="#fff"
                  fontSize={22}
                  mb={['15px', null, '24px']}
                  mt={['15px', null, '24px']}
                >
                  {tagline}
                </Heading>
              )}
              {bio && (
                <Text
                  fontSize={'18px'}
                  color={'#fff'}
                  fontWeight={400}
                  mb={['23px', null, '33px']}
                >
                  {bio}
                </Text>
              )}
              {social && (
                <Social color="#fff" borderColor="#fff" social={social} />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AuthorArea;
